/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/bench.glb");
  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            castShadow
            geometry={nodes.bench_tri.geometry}
            material={materials.lambert4}
          />
        </group>
      </group>
    </>
  );
}

useGLTF.preload("/bench.glb");
